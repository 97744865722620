/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import MenuBar from '../@lekoarts/gatsby-theme-cara/components/menu-bar';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../@lekoarts/gatsby-theme-cara/constants/theme.js';
import SEO from '@lekoarts/gatsby-theme-cara/src/components/seo';
import PrivacyMDX from '../@lekoarts/gatsby-theme-cara/sections/privacy.mdx';

const CustomPrivacyPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <SEO title="Privacy Policy" />
      <Layout className="Blog-post-page">
        <MenuBar />
        <div className="container">
          <PrivacyMDX />
        </div>
      </Layout>
    </ThemeProvider>
  );
};

export default CustomPrivacyPage;
